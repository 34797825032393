<template>
	<div class="ueditor">
			<div style="margin-top: 15px;width:50%;">
				 <el-select v-model="value" placeholder="请选择"  @change="setLangage">
				    <el-option
				      v-for="item in langage"
				      :key="item.value"
				      :label="item.label"
				      :value="item.value">
				      <span style="float: left">{{ item.label }}</span>
				      <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span>
				    </el-option>
				  </el-select>
				  <div style="margin-top: 20px" class="button-app">
				    <el-button type="text" @click="openAdd" style="border:1px solid;padding:6px;">添加赞助商</el-button>
				  </div>
			</div>
			<el-table
			    :data="tableData"
			    style="width: 100%;border-top:1px solid #EBEEF5;margin-top:20px;">
			    <el-table-column
			      label="名称"
			      prop="name">
			    </el-table-column>
			    <el-table-column
			      align="right"
				  label="操作"
				   :width="tdWidth">
			      <template slot-scope="scope">
					  <el-button
					         size="mini"
							 class="idbutton"
					         @click="selectDetail(scope.$index, scope.row)">查看详情</el-button>  
					<el-button
					    size="mini"
					    @click="selectEn(scope.$index, scope.row)">{{langeValue}}版</el-button>
			        <el-button
			          size="mini"
					  class="button-app"
			          @click="handleEdit(scope.$index, scope.row)">修改</el-button>
			        <el-button
			          size="mini"
			          type="danger"
					  class="button-app"
			          @click="handleDelete(scope.$index, scope.row)">删除</el-button>
			      </template>
			    </el-table-column>
			</el-table>
			  <div style="display: flex;justify-content:flex-end;">
				   
				   <div class="block" style="margin-top: 20px">
				      <el-pagination
				        @size-change="handleSizeChange"
				        @current-change="handleCurrentChange"
				        :current-page="currentPage4"
				        :page-sizes="[15,25,40]"
				        :page-size="pageSize"
				        layout="total, sizes, prev, pager, next, jumper"
				        :total="dataAll">
				      </el-pagination>
				    </div>
			  </div>	
				  <el-dialog title="赞助商" :visible.sync="dialogFormVisible" :before-close="cancel">
				    <el-form :model="form" ref="form">
				       <el-form-item label="名称" :label-width="formLabelWidth">
					    <el-input v-model="form.name" v-if="!detail"></el-input>
						<span v-if="detail">{{form.name}}</span>
					  </el-form-item>  
					  <el-form-item label="语言" :label-width="formLabelWidth" :class="{'active':!isAdd}">
					    <el-select v-model="form.lang" placeholder="请选择语言版本" v-if="!detail">
					      <el-option label="中文" value="zh"></el-option>
					      <el-option label="英文" value="en"></el-option>
					    </el-select>
						<span v-if="detail">{{form.name}}</span>
					  </el-form-item>
					   <el-form-item label="logo图片" :label-width="formLabelWidth">
					    <el-upload
					      action="/api/upload"
						  v-if="!detail"
						  :headers="formdata"
						  :limit="1"
						  :on-success="setImage"
						  :file-list="fileListImage"
						  list-type="picture"
					      >
						   <el-button size="small" type="primary">点击上传</el-button>
					    </el-upload>
					    <el-dialog :visible.sync="dialogVisible">
					      <img width="100%" :src="form.image" alt="">
					    </el-dialog>
						<span v-if="detail">
						<el-image
							style="width: 100px; height: 100px"
							:src="urlimg"
						></el-image></span>
					  </el-form-item>
					  <el-form-item label="内容图片上传" :label-width="formLabelWidth" v-if="!detail">
					  					  <el-upload
					  					    action="/api/upload"
											:headers="formdata"
											:on-success="setContentImage"
											:show-file-list="false"
					  					    >
					  					     <el-button size="small" type="primary">点击上传</el-button>
					  										 <span>编辑内容时需要图片时点击这里上传</span>
					  					  </el-upload>  
					  </el-form-item>
				      <el-form-item label="内容" :label-width="formLabelWidth">
						<div id="wangeditor" v-if="!detail">
						    <div ref="editorElem" style="text-align:left;"></div>
						 </div>
						 <div id="contentDitor" v-html="contentD()" v-if="detail"></div>
				      </el-form-item>
				    </el-form>
				    <div slot="footer" v-if="!detail" class="dialog-footer" style="text-align: center;">
				      <el-button @click="cancel">取 消</el-button>
				      <el-button type="primary" @click="sureNews">确 定</el-button>
				    </div>
				  </el-dialog>
				  
		</div>
	
</template>

<script>
	import {getSponsorData,addSponsorData,getSponsorDataId,deleteSponsorDataId,editSponsorDataId} from "../../request/sponsor.js"
	import foowwLocalStorage from "../../utils/foow.js";
	import E from "wangeditor";
	
	export default {
		name:"sponsor",
		data(){
			return {
					newsData:{},
					dataAll:0,
					pageSize:15,
					page:1,
					currentPage4: 0,
					input3: '',
					select: '',
					langage:[
						{
							value: 'en',
							label: '英语'
						},
						{
							value: 'zh',
							label: '中文'
						}
					],
					value:"zh",
					tableData: [],
					search: '',
					dialogFormVisible: false,
					form: {
					  draft:false,
					  author:"管理员",
					},
					formLabelWidth: '120px',
					formdata:{token:""},
					dialogVisible: false,
					editor: null,
					editorContent: '',
					isFirst:0,
					isAdd:true,
					fileListImage:[],
					fileListBgImage:[],
					token:"",
					contentImg:'',
					fileListContentImage:[],
					langeValue:'英文',
					id:'',
					detail:false,
					contentDitor:'',
					tdWidth:'',
					urlimg:"",
					urlbgimg:""
			}
		},
		async created(){
			this.formdata.token = await foowwLocalStorage.get("token").token;
			this.token = await foowwLocalStorage.get("token").token;
			this.getData();
		},
		methods: {
			contentD(){
				return this.contentDitor = this.form.content;
			},
			selectDetail(index,row){
				this.editorContent = "";
				this.dialogFormVisible = true;
				this.form = row;
				this.contentD();
				this.detail = true;
				var obj = {};
				this.fileListImage = [];
				let indexd = this.form.image.lastIndexOf('\/');
				let fileName  = this.form.image.substring(indexd + 1, this.form.image.length); //最后的文件名截取出来
				this.$set(obj,'name',fileName);
				this.$set(obj,'url',"http://havc-cms.insfilter.com"+this.form.image);  //修改files里面的结构（name,url）
				this.fileListImage.push(obj);
				this.urlimg = "http://havc-cms.insfilter.com"+this.form.image;
			},
			open(message,id) {
			       this.$confirm(message, '提示', {
			         confirmButtonText: '确定',
			         cancelButtonText: '取消',
			         type: 'warning',
			         center: true
			       }).then(() => {
						this.id = id;
			         this.openAdd();
			       }).catch(() => {
			         this.$message({
			           type: 'info',
			           message: '取消'
			         });
			       });
			     },
			tixinWarning(message){
				this.$message({
				    message: message,
				    type: 'warning'
				});
			},
			async selectEn(index,row){
				const id = row.id;
				console.log(typeof id)
				let en_id =  id.split('_')[0];
				let message = ''
				let success = '';
				if(id.split('_')[1] === 'zh'){
					en_id = en_id + '_en';
					message = '没用该英文版，是否添加';
					success = '有该英文版';
				}else{
					en_id = en_id + '_zh';
					message = '没用该中文版，是否添加';
					success = '有该中文版';
				}
				const objContent = {
					token:this.token
				}
				const data = await getSponsorDataId(objContent,en_id);
				if(data.code === 1006){
					this.open(message,en_id);
				}else if(data.code === 200){
					this.selectDetail(1,data.data)
				}
				console.log(data)
			},
			setContentImage(response, file, fileList){
				console.log(response);
				if(response.code === 200){
					this.contentImg = 'http://havc-cms.insfilter.com'+response.data.src[0];
					const imgurl = "<img src="+this.contentImg+" />"
					this.editor.txt.append(imgurl);
				}
			},
			setLangage(id){
				console.log(id)
				this.value = id;
				if(this.value === 'en'){
					this.langeValue = '中文';
				}else{
					this.langeValue = '英文'
				}
				this.getData();
			},
			async sureNews(form){
				if(this.isAdd){
					this.$refs.form.validate(async (valid) => {
					  if (valid) {
						  this.form.token = this.token;
								console.log(this.form);
								const data = await addSponsorData(this.form);
								if(data.code === 200){
									this.tixi("添加成功");
									this.id = "";
									this.dialogFormVisible = false;
									this.detail = false;
									this.getData();
								}else{
									this.tixinError(data.message)
								}
					  } else {
					    console.log('error submit!!');
					    return false;
					  }
					});
				}else{
					this.$refs.form.validate(async (valid) => {
					  if (valid) {
						  this.form.token = this.token;
								console.log(this.form);
								const data = await editSponsorDataId(this.form,this.form.id);
								if(data.code === 200){
									this.tixi("修改成功")
									this.dialogFormVisible = false;
									this.getData();
								}else{
									this.tixinError(data.message)
								}
					  } else {
					    console.log('error submit!!');
					    return false;
					  }
					});
				}
			},
			cancel(){
				this.dialogFormVisible = false;
				this.id = "";
				this.detail = false;
				this.getData();
			},
			openAdd(){	
				this.isAdd = true;
				this.editorContent = "";
				this.dialogFormVisible = true;	
				if(this.id !== ""){
					let langC = 'en';
					if(this.value === 'en'){
						langC = 'zh';
					}
					this.form = {
						draft:false,
						author:"管理员",	
						id:this.id,
						lang:langC
						}
				}else{
					this.form = {
						draft:false,
						author:"管理员",						
						}
				}
				this.fileListContentImage = [];
				this.fileListImage = [];
			    this.fileListBgImage = [];	
				if(this.isFirst === 1){
					this.editor.destroy()
					this.editor = null
					this.createEditor();
				}else{
					setTimeout( ()=>{
						this.createEditor();
						this.isFirst = 1;
					},3000)
				}
			},
			setImage(response, file, fileList){
				console.log(response);
				this.form.image = response.data.src[0];
			},
			setBgImage(response, file, fileList){
				this.form.bg_image = response.data.src[0];
			},
			async getData(){
				const obj = {
					lang: this.value,
					page: this.page,
					size: this.pageSize,
					token:this.token
				}
				const data = await getSponsorData(obj);
				console.log(data);
				 if(data.code === 200){
					const dataNews = data.data;
					this.dataAll = dataNews.count; 
					this.tableData = dataNews.list;
					if(this.dataAll % this.pageSize === 0){
						this.currentPage4 = this.dataAll / this.pageSize + 1;	
					}else{
						this.currentPage4 = this.dataAll / this.pageSize;
					} 
				}else{
					this.tixinError(data.message)
				} 
			},
			handleSizeChange(val) {
					this.pageSize = val;
			        console.log(`每页 ${val} 条`);
					this.getData();
			},
			handleCurrentChange(val) {
					  this.page = val;
					  this.getData();
			        console.log(`当前页: ${val}`);
			},	
			async handleEdit(index, row) {
			        console.log(row);
					this.editorContent = "";
					this.dialogFormVisible = true;
					this.form = row;
					const objContent = {
						token:this.token,
					}
					const data = await getSponsorDataId(objContent,this.form.id);
					console.log(data)
					if(data.code === 200){
						this.form.content = data.data.content;
					}else{
						this.tixinError(data.message);
					}
					if(row.type === "2"){
						this.form.type= "2";
					}else{
						this.form.type= "1";
					}
					var obj = {}; 
					this.fileListImage = [];
					let indexd = this.form.image.lastIndexOf('\/');
					let fileName  = this.form.image.substring(indexd + 1, this.form.image.length); //最后的文件名截取出来
					this.$set(obj,'name',fileName);
					this.$set(obj,'url',"http://havc-cms.insfilter.com"+this.form.image);  //修改files里面的结构（name,url）
					this.fileListImage.push(obj);	
					this.isAdd = false;
					if(this.isFirst === 1){
						this.editor.destroy()
						this.editor = null
						this.createEditor();
					}else{
						setTimeout( ()=>{
							this.createEditor();
							this.isFirst = 1;	
						},3000)
					}
					
					
			},
			async handleDelete(index, row) {
			        console.log( row);
					const obj = {
						token:this.token
					}
					const data = await deleteSponsorDataId(obj,row.id);
					if(data.code === 200 ){
						this.tixi("删除成功")
						this.getData();
					}else{
						this.tixinError(data.message)
					}
			},
			submitForm(formName) {
						        this.$refs[formName].validate((valid) => {
						          if (valid) {
						            alert('submit!');
						          } else {
						            console.log('error submit!!');
						            return false;
						          }
						        });
						      },
			resetForm(formName) {
					this.$refs[formName].resetFields();
			},
			tixinError(message){
				this.$message({
				    message: message,
				    type: 'error'
				});
			},
			tixi(message){
				this.$message({
				    message: message,
				    type: 'success'
				});
			},
			createEditor(){
				//console.log(this.$refs.editorElem)
							this.editor = new E(this.$refs.editorElem);
							// 编辑器的事件，每次改变会获取其html内容
							this.editor.config.onchange = (html) => {
							  this.form.content = html;
							  //this.catchData(this.editorContent); // 把这个html通过catchData的方法传入父组件
							};
							this.editor.create(); // 创建富文本实例				
							this.editor.txt.html(this.form.content) 
			},
			searchFormWidth() {
				      let w = window.innerWidth;
					  if(w <= 767) {
						  console.log('120');
					     this.tdWidth = '120'
					   }else{
						   console.log('440');
						 this.tdWidth = '440'
					   }    
			},
			langType(value){
				if(value === 'zh'){
					return '中文'
				}else if(value === 'en'){
					return '英文'
				}
			}					
		},
		mounted() {
			this.searchFormWidth();
		}
		
	}
</script>

<style>
	#contentDitor img{
			  max-width: 450px;
			  }
			  @media screen and (max-width: 767px) {
			  			  #contentDitor img{
			  			  	max-width: 226px;
			  			  }
			  				.el-form-item__label{
			  					text-align:none;
			  					
			  				} 
			  				.el-pager{
			  					display:none;
			  				}
			  				.el-pagination .el-select .el-input{
			  					margin:0;
			  				}
			  }
</style>
