import request from "./axios.js";


/**
 * 赞助商查询
 *
 * @param {Object} data - get对象数据{params:{a:1,b:2}}
 */
export function getSponsorData(data) {
  return request.get("/api/sponsor/",{params:data} );
}

/**
 * 赞助商添加
 *
 * @param {Object} data - 对象数据
 */
export function addSponsorData(data) {
  return request.post("/api/sponsor", data);
}

/**
 * 赞助商查询id
 *
 * @param {Object} data - get对象数据{params:{a:1,b:2}}
 */
export function getSponsorDataId(data,id) {
  return request.get("/api/sponsor/"+id,{params:data} );
}

/**
 * 赞助商删除
 *
 * @param {Object} data - 对象数据
 */
export function deleteSponsorDataId(data,id) {
  return request.delete("/api/sponsor/"+id,{params: data});
}


/**
 * 赞助商修改
 *
 * @param {Object} data - 对象数据
 */
export function editSponsorDataId(data,id) {
  return request.put("/api/sponsor/"+id,data);
}